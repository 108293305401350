import themeConfig from '@/../themeConfig';

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || 'vertical',
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  pageTitle: '',
  theme: themeConfig.theme || 'light',
  themePrimaryColor: themeConfig.colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
  // auth: JSON.parse(window.localStorage.getItem(STORAGE_AUTH_KEY) || '{}'),

  // Others utils
  breadcrumb: [],
  isReturningFromBreadcrumb: false,
  feedbackModalKey: 0,
};

export default state;
