import {
  STORAGE_AUTH_USER_KEY,
} from '@/store/auth/auth.state';

const user = JSON.parse(window.localStorage.getItem(STORAGE_AUTH_USER_KEY) || null);

let url = 'http://localhost:3000';

if (process.env.NODE_ENV === 'production') {
  url = 'https://api.grapesend.io';
} else if (process.env.NODE_ENV === 'staging' || (process.env.VUE_APP_STAGING && process.env.VUE_APP_STAGING.toLowerCase() === 'true')) {
  url = 'https://grape-perks-api-staging.herokuapp.com';
} else if (process.env.NODE_ENV === 'preview' || (process.env.VUE_APP_PREVIEW && process.env.VUE_APP_PREVIEW.toLowerCase() === 'true')) {
  url = 'https://grape-perks-api-preview.herokuapp.com';
}

const configApi = {
  url,
  timeout: 0,
  token: null,
  refreshToken: null,
  userId: user ? user.id : null,
  limitApiCalls: process.env.VUE_APP_LIMIT_API_CALLS
    ? process.env.VUE_APP_LIMIT_API_CALLS.toLowerCase() === 'true'
    : true,
  fakeDb: process.env.VUE_APP_FAKE_DB
    ? process.env.VUE_APP_FAKE_DB.toLowerCase() === 'true'
    : false,
  baseRoutesUrls: {
    appServices: '/app-services',
    appServiceCountries: '/app-service-countries',
    appSettings: '/app-settings',
    attributes: 'attributes',
    auth: '/auth',
    callerIds: '/caller-ids',
    contacts: '/contacts',
    feedback: '/feedback',
    inboundSms: '/inbound-sms',
    notifications: '/notifications',
    segments: 'segments',
    smsCampaigns: '/sms-campaigns',
    smsTemplates: '/sms-templates',
    stripe: '/stripe',
    tags: 'tags',
    tenants: '/tenants',
    users: '/users',
    userSettings: '/settings',

    mmsTemplates: '/mms-templates',
    emailTemplates: '/email-templates',
    recordings: '/recordings',
    mailerIds: '/mailer-ids',
    mmsCampaigns: '/mms-campaigns',
    voiceCampaigns: '/voice-campaigns',
    emailCampaigns: '/email-campaigns',
    audiences: '/audiences',
  },
};

export default configApi;
