import enums from '@/enums';

// en, de, fr, pt
export default {
  en: {
    $Brand: {
      Name: 'GrapeSend',
    },
    $General: {
      OptInAgreement: 'Opt-in agreement',
      Comment: 'No comment | Comment | Comments',
      Subscription: 'Subscription',
      Buy: 'Buy',
      Billed: 'Billed',
      Resubscribe: 'Resubscribe',
      DedicatedNumber: 'No dedicated numbers | Dedicated number | Dedicated numbers',
      Rating: 'Rating',
      Saving: 'Saving',
      TextField: 'Text field',
      Submit: 'Submit',
      Notification: 'No Notifications | Notification | Notifications',
      Shop: 'No Shops | Shop | Shops',
      ViewAll: 'View all',
      Important: 'Important',
      Accept: 'Accept',
      On: 'On',
      Off: 'Off',
      Enable: 'Enable',
      Disable: 'Disable',
      Enabled: 'Enabled',
      Disabled: 'Disabled',
      Sent: 'Sent',
      Home: 'Home',
      Contact: 'Contact',
      GoToPayment: 'Go to payment',
      Review: 'No Reviews | Review | Reviews',
      Score: 'Score',
      This: 'This',
      ReviewLink: 'Review link',
      Logo: 'Logo',
      Security: 'Security',
      General: 'General',
      URL: 'URL',
      Insert: 'Insert',
      Logout: 'Logout',
      Copyright: 'Copyright',
      AllRightsReserved: 'All rights reserved',
      TermsAndConditions: 'Terms & Conditions',
      And: 'And',
      Or: 'Or',
      MoreInfo: 'More info',
      Confirm: 'Confirm',
      Skip: 'Skip',
      AskMeLater: 'Ask me later',
      OptOut: 'No Opt-out | Opt-out | Opt-outs',
      OptIn: 'No Opt-in | Opt-in | Opt-in',
      BusinessName: 'No business name | Business name | Business names',
      JobTitle: 'No job title | Job title | Job titles',
      DateAndTime: 'Date / Time',
      CreatedAt: 'Created',
      UpdatedAt: 'Updated',
      From: 'From',
      Service: 'No Service | Service | Services',
      Scheduled: 'Scheduled',
      Schedule: 'Schedule',
      Draft: 'No Draft | Draft | Drafts',
      Verify: 'Verify',
      FAQ: 'No FAQs | FAQ | FAQs',
      Documentation: 'Documentation',
      KnowledgeBase: 'Knowledge Base',
      List: 'List',
      BlackList: 'Suppression list',
      Subscribed: 'Subscribed',
      Unsubscribed: 'Unsubscribed',
      TransactionalOnly: 'Transactional only',
      Dashboard: 'Dashboard',
      Analytics: 'Analytics',
      Feedback: 'Feedback',
      Campaign: 'No Campaigns | Campaign | Campaigns',
      NewCampaign: 'New campaign',
      DoNotCallRegister: 'Do Not Call Register',
      DNCAbbreviation: 'DNC',
      DNCRevisionDate: 'DNC Wash Date',
      StraightToVoice: 'Voice',
      More: 'More',
      Back: 'Back',
      Test: 'Test',
      Email: 'No Email | Email | Emails',
      Password: 'Password',
      CurrentPassword: 'Current password',
      NewPassword: 'New password',
      ConfirmNewPassword: 'Confirm new password',
      Name: 'Name',
      Key: 'Key',
      Abbreviation: 'Abbreviation',
      FirstName: 'First Name',
      LastName: 'Last Name',
      CompanyName: 'Company Name',
      Company: 'No Company | Company | Companies',
      Address: 'No Address | Address | Addresses',
      Reset: 'Reset',
      Apply: 'Apply',
      Next: 'Next',
      Status: 'Status',
      NumberContacts: 'Number Of Contacts',
      Search: 'Search',
      Audience: 'No Audience | Audience | Audiences',
      Attribute: 'No Attribute | Attribute | Attributes',
      Permissions: 'Permissions',
      Cancel: 'Cancel',
      DonNotCancel: 'Don\'t Cancel',
      Role: 'Role',
      Invite: 'Invite',
      Invited: 'Invited',
      Active: 'Active',
      Inactive: 'Inactive',
      User: 'No User | User | Users',
      Merchants: 'Business',
      View: 'View',
      Resend: 'Resend',
      Edit: 'Edit',
      Create: 'Create',
      Save: 'Save',
      Update: 'Update',
      Delete: 'Delete',
      Export: 'Export',
      All: 'All',
      Any: 'Any',
      None: 'None',
      CurrentPage: 'Current page',
      Admin: 'Admin',
      Administrator: 'Administrator',
      Owner: 'Owner',
      Collaborator: 'No Collaborator | Collaborator | Collaborators',
      Permission: 'Permission',
      Description: 'Description',
      Column: 'Column',
      Price: 'Price',
      NumberAbbreviation: 'Nº',
      PriceInCurrency: 'Price({currency})',
      AddNew: 'Create new',
      Message: 'No message | Message | Messages',
      Voicemail: 'No voicemail | Voicemail | Voicemails',
      Private: 'Private',
      Public: 'Public',
      Share: 'Share',
      Collaborators: 'Collaborators',
      Close: 'Close',
      UploadFile: 'Upload File',
      Browse: 'Browse',
      Uploading: 'Uploading',
      Upload: 'Upload',
      Audio: 'Audio',
      Image: 'Image',
      Open: 'Open',
      Groups: 'Groups',
      FullName: 'Full Name',
      Phone: 'No Phone | Phone | Phones',
      PhoneNumber: 'No Phone number | Phone number | Phones numbers',
      Tag: 'No Tags | Tag | Tags',
      Item: 'No Item | Item | Items',
      Select: 'Select',
      Yes: 'Yes',
      No: 'No',
      Text: 'Text',
      Date: 'Date',
      Number: 'Number',
      Boolean: 'Boolean',
      Category: 'No Category | Category | Categories',
      New: 'No New | New | News',
      Add: 'Add',
      inPreposition: ' in {end}',
      Clean: 'Clean',
      Clear: 'Clear',
      Check: 'Check',
      Code: 'Code',
      NotFound: 'Not found',
      Setting: 'No Setting | Setting | Settings',
      Establishment: 'No Establishments | Establishment | Establishments',
      ContactName: 'No Contact Name | Contact Name | Contact Names',
      Account: 'No Account | Account | Accounts',
      Action: 'No Action | Action | Actions',
      Type: 'No Type | Type | Types',
      SenderID: 'No Sender ID | Sender ID | Sender IDs',
      Compare: 'Compare',
      Subject: 'Subject',
      Send: 'Send',
      Trash: 'Bin',
      TimeZone: 'No Time zone | Time zone | Time zones',
      Others: 'Others',
      Restore: 'Restore',
      Import: 'Import',
      DeliveryTime: 'Delivery time',
      Transactional: 'Transactional',
      Marketing: 'Marketing',
      Continue: 'Continue',
      SaveAndContinue: 'Save & continue',
      SaveAndQuit: 'Save & quit',
      Discard: 'Discard',
      StartOver: 'Star over',
      Bounced: 'Bounced',
      Outbound: 'Outbound',
      Inbound: 'Inbound',
      SMS: 'SMS',
      Clone: 'Clone',
      SendCampaign: 'Send Campaign',
      Fit: 'Fit',
      Of: 'Of',
      To: 'To',
      Filter: 'No filter | Filter | Filters',
      Undetermined: 'Undetermined',
      Selected: 'Selected',
      Preview: 'Preview',
      Prev: 'Prev',
      Recharge: 'Recharge',
      ResetSearch: 'Reset search',
      Format: 'No format | Format | Formats',
      Separator: 'No Separator | Separator | Separators',
      Refresh: 'Refresh',
      Copy: 'Copy',
      Visit: 'Visit',
      ComingSoon: 'Coming Soon',
      Error: 'Error',
      404: '404',
      500: '500',
      NotAuthorized: 'Not Authorized',
      Maintenance: 'Maintenance',
      Profile: 'Profile',
      Billing: 'Billing',
      Remove: 'Remove',
      Amount: 'Amount',
      Month: 'Month',
      Purchase: 'No Purchase | Purchase | Purchases',
      Disconnect: 'Disconnect',
      Subtotal: 'Subtotal',
      Total: 'Total',
      Existing: 'Existing',
      Default: 'Default',
      Pay: 'Pay',
      Expiry: 'Expiry',
      ExpiryDate: 'Expiry date',
      Here: 'Here',
      Country: 'No country | Country | Countries',
      State: 'No state | State | States',
      City: 'No city | City | Cities',
      ZipPostalCode: 'ZIP / Postal Code',
      AddressLine1: 'Address line 1',
      AddressLine2: 'Address line 2',
      History: 'No history | History | Histories',
      Template: 'No Template | Template | Templates',
      Unicode: 'Unicode',
      SharedNumber: 'Shared number - replies to account',
      ReplyNumber: 'Reply number',
      Overview: 'Overview',
      Sending: 'Sending',
      Spent: 'Spent',
      Available: 'Available',
      Recording: 'Recording',
      QuickSMS: 'Quick SMS',
      QuickVoice: 'Quick Voice',
      MarketingStatus: 'Status',
      CardEnding: 'card ending',
    },
    $Enums: {
      Campaign: {
        Status: {
          [enums.Campaign.Status.PENDING]: 'Scheduled',
          [enums.Campaign.Status.DRAFT]: 'Draft',
          [enums.Campaign.Status.RUNNING]: 'Running',
          [enums.Campaign.Status.COMPLETED]: 'Sent',
          [enums.Campaign.Status.CANCELED]: 'Canceled',
        },
        CancellationReason: {
          [enums.Campaign.CancellationReason.MANUAL_CANCELLATION]: 'Manual cancellation',
          [enums.Campaign.CancellationReason.INSUFFICIENT_FUNDS]: 'Insufficient funds',
        },
      },
      Sender: {
        Status: {
          [enums.Sender.Status.VERIFIED]: 'Verified',
          [enums.Sender.Status.UNVERIFIED]: 'Unverified',
        },
        Caller: {
          Type: {
            [enums.Sender.Caller.Type.SHARED_NUMBER]: 'Shared number - replies to account',
            [enums.Sender.Caller.Type.PHONE_NUMBER]: 'Phone number - replies to headset',
            [enums.Sender.Caller.Type.DEDICATED_NUMBER]: 'Dedicated number - replies to account',
            [enums.Sender.Caller.Type.BUSINESS_NAME]: 'Business name - no replies',
          },
          DedicatedNumberStatus: {
            [enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT]: 'Processing payment',
            [enums.Sender.Caller.DedicatedNumberStatus.ACTIVE]: 'Active',
            [enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT]: 'Missing payment',
            [enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT]: 'Expired payment',
            [enums.Sender.Caller.DedicatedNumberStatus.CANCELED]: 'Canceled',
          },
        },
      },
      Contact: {
        MarketingStatus: {
          [enums.Contact.MarketingStatus.SUBSCRIBED]: 'Subscribed',
          [enums.Contact.MarketingStatus.UNSUBSCRIBED]: 'Unsubscribed',
        },
      },
      AppFeedbackType: {
        [enums.AppFeedbackType.NO_COMMENTS]: 'No comments.',
        [enums.AppFeedbackType.SUGGEST_IMPROVEMENT]: 'Suggest an improvement.',
        [enums.AppFeedbackType.GIVE_US_COMPLIMENT]: 'Give us a compliment.',
        [enums.AppFeedbackType.EXPERIENCED_PROBLEM]: 'I experienced a problem.',
        [enums.AppFeedbackType.NOT_FIND_SOMETHING]: 'I couldn\'t find what I was looking for.',
      },
      $Attributes: {
        $Type: {
          [enums.Attributes.Type.TEXT]: 'Text',
          [enums.Attributes.Type.DATE]: 'Date',
          [enums.Attributes.Type.NUMBER]: 'Number',
          [enums.Attributes.Type.BOOL]: 'Boolean',
          [enums.Attributes.Type.CATEGORY]: 'Category',
        },
      },
      Tenants: {
        APP_SERVICE_ITEM_BILLING_INTERVAL: {
          [enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.MONTH]: 'Monthly',
          [enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.TRIMESTER]: 'Quarterly',
          [enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.SEMESTER]: 'Semi-annually',
          [enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.YEAR]: 'Yearly',
        },
        APP_SERVICE_ITEM_STATUS: {
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE]: 'Incomplete',
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.INCOMPLETE_EXPIRED]: 'Expired',
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.ACTIVE]: 'Active',
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE]: 'Past due',
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.EXPIRED]: 'Expired',
          [enums.Tenants.APP_SERVICE_ITEM_STATUS.CANCELED]: 'Canceled',
        },
      },
    },
    $TimeZones: {
      'Pacific/Niue': 'Niue Time',
      'Pacific/Pago_Pago': 'Samoa Standard Time',
      'Pacific/Rarotonga': 'Cook Islands Standard Time',
      'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
      'America/Adak': 'Hawaii-Aleutian Time',
      'Pacific/Tahiti': 'Tahiti Time',
      'Pacific/Marquesas': 'Marquesas Time',
      'America/Anchorage': 'Alaska Time - Anchorage',
      'America/Juneau': 'Alaska Time - Juneau',
      'America/Metlakatla': 'Alaska Time - Metlakatla',
      'America/Nome': 'Alaska Time - Nome',
      'America/Sitka': 'Alaska Time - Sitka',
      'America/Yakutat': 'Alaska Time - Yakutat',
      'Pacific/Gambier': 'Gambier Time',
      'America/Los_Angeles': 'Pacific Time - Los Angeles',
      'America/Tijuana': 'Pacific Time - Tijuana',
      'America/Vancouver': 'Pacific Time - Vancouver',
      'Pacific/Pitcairn': 'Pitcairn Time',
      'America/Hermosillo': 'Mexican Pacific Standard Time - Hermosillo',
      'America/Mazatlan': 'Mexican Pacific Standard Time - Mazatlan',
      'America/Dawson_Creek': 'Mountain Standard Time - Dawson Creek',
      'America/Fort_Nelson': 'Mountain Standard Time - Fort Nelson',
      'America/Phoenix': 'Mountain Standard Time - Phoenix',
      'America/Boise': 'Mountain Time - Boise',
      'America/Cambridge_Bay': 'Mountain Time - Cambridge Bay',
      'America/Ciudad_Juarez': 'Mountain Time - Ciudad Juárez',
      'America/Denver': 'Mountain Time - Denver',
      'America/Edmonton': 'Mountain Time - Edmonton',
      'America/Yellowknife': 'Mountain Time - Edmonton',
      'America/Inuvik': 'Mountain Time - Inuvik',
      'America/Dawson': 'Yukon Time - Dawson',
      'America/Whitehorse': 'Yukon Time - Whitehorse',
      'America/Bahia_Banderas': 'Central Standard Time - Bahía de Banderas',
      'America/Belize': 'Central Standard Time - Belize',
      'America/Chihuahua': 'Central Standard Time - Chihuahua',
      'America/Costa_Rica': 'Central Standard Time - Costa Rica',
      'America/El_Salvador': 'Central Standard Time - El Salvador',
      'America/Guatemala': 'Central Standard Time - Guatemala',
      'America/Managua': 'Central Standard Time - Managua',
      'America/Merida': 'Central Standard Time - Mérida',
      'America/Mexico_City': 'Central Standard Time - Mexico City',
      'America/Monterrey': 'Central Standard Time - Monterrey',
      'America/Regina': 'Central Standard Time - Regina',
      'America/Swift_Current': 'Central Standard Time - Swift Current',
      'America/Tegucigalpa': 'Central Standard Time - Tegucigalpa',
      'America/North_Dakota/Beulah': 'Central Time - Beulah, North Dakota',
      'America/North_Dakota/Center': 'Central Time - Center, North Dakota',
      'America/Chicago': 'Central Time - Chicago',
      'America/Indiana/Knox': 'Central Time - Knox, Indiana',
      'America/Matamoros': 'Central Time - Matamoros',
      'America/Menominee': 'Central Time - Menominee',
      'America/North_Dakota/New_Salem': 'Central Time - New Salem, North Dakota',
      'America/Ojinaga': 'Central Time - Ojinaga',
      'America/Rankin_Inlet': 'Central Time - Rankin Inlet',
      'America/Resolute': 'Central Time - Resolute',
      'America/Indiana/Tell_City': 'Central Time - Tell City, Indiana',
      'America/Rainy_River': 'Central Time - Winnipeg',
      'America/Winnipeg': 'Central Time - Winnipeg',
      'Pacific/Galapagos': 'Galapagos Time',
      'America/Eirunepe': 'Acre Standard Time - Eirunepe',
      'America/Rio_Branco': 'Acre Standard Time - Rio Branco',
      'America/Bogota': 'Colombia Standard Time',
      'America/Havana': 'Cuba Time',
      'Pacific/Easter': 'Easter Island Time',
      'America/Cancun': 'Eastern Standard Time - Cancún',
      'America/Jamaica': 'Eastern Standard Time - Jamaica',
      'America/Panama': 'Eastern Standard Time - Panama',
      'America/Detroit': 'Eastern Time - Detroit',
      'America/Grand_Turk': 'Eastern Time - Grand Turk',
      'America/Indiana/Indianapolis': 'Eastern Time - Indianapolis',
      'America/Iqaluit': 'Eastern Time - Iqaluit',
      'America/Pangnirtung': 'Eastern Time - Iqaluit',
      'America/Kentucky/Louisville': 'Eastern Time - Louisville',
      'America/Indiana/Marengo': 'Eastern Time - Marengo, Indiana',
      'America/Kentucky/Monticello': 'Eastern Time - Monticello, Kentucky',
      'America/New_York': 'Eastern Time - New York',
      'America/Indiana/Petersburg': 'Eastern Time - Petersburg, Indiana',
      'America/Port-au-Prince': 'Eastern Time - Port-au-Prince',
      'America/Nipigon': 'Eastern Time - Toronto',
      'America/Thunder_Bay': 'Eastern Time - Toronto',
      'America/Toronto': 'Eastern Time - Toronto',
      'America/Indiana/Vevay': 'Eastern Time - Vevay, Indiana',
      'America/Indiana/Vincennes': 'Eastern Time - Vincennes, Indiana',
      'America/Indiana/Winamac': 'Eastern Time - Winamac, Indiana',
      'America/Guayaquil': 'Ecuador Time',
      'America/Lima': 'Peru Standard Time',
      'America/Boa_Vista': 'Amazon Standard Time - Boa Vista',
      'America/Campo_Grande': 'Amazon Standard Time - Campo Grande',
      'America/Cuiaba': 'Amazon Standard Time - Cuiaba',
      'America/Manaus': 'Amazon Standard Time - Manaus',
      'America/Porto_Velho': 'Amazon Standard Time - Porto Velho',
      'America/Barbados': 'Atlantic Standard Time - Barbados',
      'America/Martinique': 'Atlantic Standard Time - Martinique',
      'America/Puerto_Rico': 'Atlantic Standard Time - Puerto Rico',
      'America/Santo_Domingo': 'Atlantic Standard Time - Santo Domingo',
      'Atlantic/Bermuda': 'Atlantic Time - Bermuda',
      'America/Glace_Bay': 'Atlantic Time - Glace Bay',
      'America/Goose_Bay': 'Atlantic Time - Goose Bay',
      'America/Halifax': 'Atlantic Time - Halifax',
      'America/Moncton': 'Atlantic Time - Moncton',
      'America/Thule': 'Atlantic Time - Thule',
      'America/La_Paz': 'Bolivia Time',
      'America/Guyana': 'Guyana Time',
      'America/Caracas': 'Venezuela Time',
      'America/St_Johns': 'Newfoundland Time',
      'America/Argentina/Buenos_Aires': 'Argentina Standard Time - Buenos Aires',
      'America/Argentina/Catamarca': 'Argentina Standard Time - Catamarca',
      'America/Argentina/Cordoba': 'Argentina Standard Time - Cordoba',
      'America/Argentina/Jujuy': 'Argentina Standard Time - Jujuy',
      'America/Argentina/La_Rioja': 'Argentina Standard Time - La Rioja',
      'America/Argentina/Mendoza': 'Argentina Standard Time - Mendoza',
      'America/Argentina/Rio_Gallegos': 'Argentina Standard Time - Rio Gallegos',
      'America/Argentina/Salta': 'Argentina Standard Time - Salta',
      'America/Argentina/San_Juan': 'Argentina Standard Time - San Juan',
      'America/Argentina/San_Luis': 'Argentina Standard Time - San Luis',
      'America/Argentina/Tucuman': 'Argentina Standard Time - Tucuman',
      'America/Argentina/Ushuaia': 'Argentina Standard Time - Ushuaia',
      'America/Araguaina': 'Brasilia Standard Time - Araguaina',
      'America/Bahia': 'Brasilia Standard Time - Bahia',
      'America/Belem': 'Brasilia Standard Time - Belem',
      'America/Fortaleza': 'Brasilia Standard Time - Fortaleza',
      'America/Maceio': 'Brasilia Standard Time - Maceio',
      'America/Recife': 'Brasilia Standard Time - Recife',
      'America/Santarem': 'Brasilia Standard Time - Santarem',
      'America/Sao_Paulo': 'Brasilia Standard Time - Sao Paulo',
      'America/Santiago': 'Chile Time',
      'Atlantic/Stanley': 'Falkland Islands Standard Time',
      'America/Cayenne': 'French Guiana Time',
      'Antarctica/Palmer': 'Palmer Time',
      'America/Asuncion': 'Paraguay Time',
      'America/Punta_Arenas': 'Punta Arenas Time',
      'Antarctica/Rothera': 'Rothera Time',
      'America/Miquelon': 'St. Pierre & Miquelon Time',
      'America/Paramaribo': 'Suriname Time',
      'America/Montevideo': 'Uruguay Standard Time',
      'America/Noronha': 'Fernando de Noronha Standard Time',
      'Atlantic/South_Georgia': 'South Georgia Time',
      'America/Nuuk': 'West Greenland Time',
      'Atlantic/Azores': 'Azores Time',
      'Atlantic/Cape_Verde': 'Cape Verde Standard Time',
      'America/Scoresbysund': 'East Greenland Time',
      UTC: 'Coordinated Universal Time',
      'Etc/GMT': 'Greenwich Mean Time',
      'Africa/Abidjan': 'Greenwich Mean Time - Abidjan',
      'Africa/Bissau': 'Greenwich Mean Time - Bissau',
      'America/Danmarkshavn': 'Greenwich Mean Time - Danmarkshavn',
      'Africa/Monrovia': 'Greenwich Mean Time - Monrovia',
      'Atlantic/Reykjavik': 'Greenwich Mean Time - Reykjavik',
      'Africa/Sao_Tome': 'Greenwich Mean Time - São Tomé',
      'Europe/Dublin': 'Ireland Time',
      'Antarctica/Troll': 'Troll Time',
      'Europe/London': 'United Kingdom Time',
      'Atlantic/Canary': 'Western European Time - Canary',
      'Atlantic/Faroe': 'Western European Time - Faroe',
      'Europe/Lisbon': 'Western European Time - Lisbon',
      'Atlantic/Madeira': 'Western European Time - Madeira',
      'Africa/Algiers': 'Central European Standard Time - Algiers',
      'Africa/Tunis': 'Central European Standard Time - Tunis',
      'Europe/Amsterdam': 'Central European Time - Amsterdam',
      'Europe/Andorra': 'Central European Time - Andorra',
      'Europe/Belgrade': 'Central European Time - Belgrade',
      'Europe/Berlin': 'Central European Time - Berlin',
      'Europe/Brussels': 'Central European Time - Brussels',
      'Europe/Budapest': 'Central European Time - Budapest',
      'Africa/Ceuta': 'Central European Time - Ceuta',
      'Europe/Copenhagen': 'Central European Time - Copenhagen',
      'Europe/Gibraltar': 'Central European Time - Gibraltar',
      'Europe/Luxembourg': 'Central European Time - Luxembourg',
      'Europe/Madrid': 'Central European Time - Madrid',
      'Europe/Malta': 'Central European Time - Malta',
      'Europe/Monaco': 'Central European Time - Monaco',
      'Europe/Oslo': 'Central European Time - Oslo',
      'Europe/Paris': 'Central European Time - Paris',
      'Europe/Prague': 'Central European Time - Prague',
      'Europe/Rome': 'Central European Time - Rome',
      'Europe/Stockholm': 'Central European Time - Stockholm',
      'Europe/Tirane': 'Central European Time - Tirane',
      'Europe/Vienna': 'Central European Time - Vienna',
      'Europe/Warsaw': 'Central European Time - Warsaw',
      'Europe/Zurich': 'Central European Time - Zurich',
      'Africa/Casablanca': 'Morocco Time',
      'Africa/Lagos': 'West Africa Standard Time - Lagos',
      'Africa/Ndjamena': 'West Africa Standard Time - Ndjamena',
      'Africa/El_Aaiun': 'Western Sahara Time',
      'Africa/Juba': 'Central Africa Time - Juba',
      'Africa/Khartoum': 'Central Africa Time - Khartoum',
      'Africa/Maputo': 'Central Africa Time - Maputo',
      'Africa/Windhoek': 'Central Africa Time - Windhoek',
      'Europe/Kaliningrad': 'Eastern European Standard Time - Kaliningrad',
      'Africa/Tripoli': 'Eastern European Standard Time - Tripoli',
      'Europe/Athens': 'Eastern European Time - Athens',
      'Asia/Beirut': 'Eastern European Time - Beirut',
      'Europe/Bucharest': 'Eastern European Time - Bucharest',
      'Africa/Cairo': 'Eastern European Time - Cairo',
      'Europe/Chisinau': 'Eastern European Time - Chisinau',
      'Asia/Gaza': 'Eastern European Time - Gaza',
      'Asia/Hebron': 'Eastern European Time - Hebron',
      'Europe/Helsinki': 'Eastern European Time - Helsinki',
      'Europe/Kiev': 'Eastern European Time - Kyiv',
      'Europe/Uzhgorod': 'Eastern European Time - Kyiv',
      'Europe/Zaporozhye': 'Eastern European Time - Kyiv',
      'Asia/Nicosia': 'Eastern European Time - Nicosia',
      'Europe/Riga': 'Eastern European Time - Riga',
      'Europe/Sofia': 'Eastern European Time - Sofia',
      'Europe/Tallinn': 'Eastern European Time - Tallinn',
      'Europe/Vilnius': 'Eastern European Time - Vilnius',
      'Asia/Famagusta': 'Famagusta Time',
      'Asia/Jerusalem': 'Israel Time',
      'Africa/Johannesburg': 'South Africa Standard Time',
      'Asia/Baghdad': 'Arabian Standard Time - Baghdad',
      'Asia/Qatar': 'Arabian Standard Time - Qatar',
      'Asia/Riyadh': 'Arabian Standard Time - Riyadh',
      'Africa/Nairobi': 'East Africa Time',
      'Asia/Amman': 'Jordan Time',
      'Europe/Kirov': 'Kirov Time',
      'Europe/Minsk': 'Moscow Standard Time - Minsk',
      'Europe/Moscow': 'Moscow Standard Time - Moscow',
      'Europe/Simferopol': 'Moscow Standard Time - Simferopol',
      'Asia/Damascus': 'Syria Time',
      'Europe/Istanbul': 'Türkiye Time',
      'Europe/Volgograd': 'Volgograd Standard Time',
      'Asia/Tehran': 'Iran Standard Time',
      'Asia/Yerevan': 'Armenia Standard Time',
      'Europe/Astrakhan': 'Astrakhan Time',
      'Asia/Baku': 'Azerbaijan Standard Time',
      'Asia/Tbilisi': 'Georgia Standard Time',
      'Asia/Dubai': 'Gulf Standard Time',
      'Indian/Mauritius': 'Mauritius Standard Time',
      'Indian/Reunion': 'Réunion Time',
      'Europe/Samara': 'Samara Standard Time',
      'Europe/Saratov': 'Saratov Time',
      'Indian/Mahe': 'Seychelles Time',
      'Europe/Ulyanovsk': 'Ulyanovsk Time',
      'Asia/Kabul': 'Afghanistan Time',
      'Indian/Kerguelen': 'French Southern & Antarctic Time',
      'Indian/Maldives': 'Maldives Time',
      'Antarctica/Mawson': 'Mawson Time',
      'Asia/Karachi': 'Pakistan Standard Time',
      'Asia/Dushanbe': 'Tajikistan Time',
      'Asia/Ashgabat': 'Turkmenistan Standard Time',
      'Asia/Samarkand': 'Uzbekistan Standard Time - Samarkand',
      'Asia/Tashkent': 'Uzbekistan Standard Time - Tashkent',
      'Asia/Aqtau': 'West Kazakhstan Time - Aqtau',
      'Asia/Aqtobe': 'West Kazakhstan Time - Aqtobe',
      'Asia/Atyrau': 'West Kazakhstan Time - Atyrau',
      'Asia/Oral': 'West Kazakhstan Time - Oral',
      'Asia/Qyzylorda': 'West Kazakhstan Time - Qyzylorda',
      'Asia/Yekaterinburg': 'Yekaterinburg Standard Time',
      'Asia/Colombo': 'India Standard Time - Colombo',
      'Asia/Kolkata': 'India Standard Time - Kolkata',
      'Asia/Kathmandu': 'Nepal Time',
      'Asia/Dhaka': 'Bangladesh Standard Time',
      'Asia/Thimphu': 'Bhutan Time',
      'Asia/Almaty': 'East Kazakhstan Time - Almaty',
      'Asia/Qostanay': 'East Kazakhstan Time - Kostanay',
      'Indian/Chagos': 'Indian Ocean Time',
      'Asia/Bishkek': 'Kyrgyzstan Time',
      'Asia/Omsk': 'Omsk Standard Time',
      'Asia/Urumqi': 'Urumqi Time',
      'Antarctica/Vostok': 'Vostok Time',
      'Indian/Cocos': 'Cocos Islands Time',
      'Asia/Yangon': 'Myanmar Time',
      'Asia/Barnaul': 'Barnaul Time',
      'Indian/Christmas': 'Christmas Island Time',
      'Antarctica/Davis': 'Davis Time',
      'Asia/Hovd': 'Hovd Standard Time',
      'Asia/Bangkok': 'Indochina Time - Bangkok',
      'Asia/Ho_Chi_Minh': 'Indochina Time - Ho Chi Minh City',
      'Asia/Krasnoyarsk': 'Krasnoyarsk Standard Time - Krasnoyarsk',
      'Asia/Novokuznetsk': 'Krasnoyarsk Standard Time - Novokuznetsk',
      'Asia/Novosibirsk': 'Novosibirsk Standard Time',
      'Asia/Tomsk': 'Tomsk Time',
      'Asia/Jakarta': 'Western Indonesia Time - Jakarta',
      'Asia/Pontianak': 'Western Indonesia Time - Pontianak',
      'Australia/Perth': 'Australian Western Standard Time',
      'Asia/Brunei': 'Brunei Darussalam Time',
      'Asia/Makassar': 'Central Indonesia Time',
      'Asia/Macau': 'China Standard Time - Macao',
      'Asia/Shanghai': 'China Standard Time - Shanghai',
      'Asia/Hong_Kong': 'Hong Kong Standard Time',
      'Asia/Irkutsk': 'Irkutsk Standard Time',
      'Asia/Kuala_Lumpur': 'Malaysia Time - Kuala Lumpur',
      'Asia/Kuching': 'Malaysia Time - Kuching',
      'Asia/Manila': 'Philippine Standard Time',
      'Asia/Singapore': 'Singapore Standard Time',
      'Asia/Taipei': 'Taipei Standard Time',
      'Asia/Choibalsan': 'Ulaanbaatar Standard Time - Choibalsan',
      'Asia/Ulaanbaatar': 'Ulaanbaatar Standard Time - Ulaanbaatar',
      'Australia/Eucla': 'Australian Central Western Standard Time',
      'Asia/Dili': 'East Timor Time',
      'Asia/Jayapura': 'Eastern Indonesia Time',
      'Asia/Tokyo': 'Japan Standard Time',
      'Asia/Pyongyang': 'Korean Standard Time - Pyongyang',
      'Asia/Seoul': 'Korean Standard Time - Seoul',
      'Pacific/Palau': 'Palau Time',
      'Asia/Chita': 'Yakutsk Standard Time - Chita',
      'Asia/Khandyga': 'Yakutsk Standard Time - Khandyga',
      'Asia/Yakutsk': 'Yakutsk Standard Time - Yakutsk',
      'Australia/Darwin': 'Australian Central Standard Time',
      'Australia/Brisbane': 'Australian Eastern Standard Time - Brisbane',
      'Australia/Lindeman': 'Australian Eastern Standard Time - Lindeman',
      'Pacific/Guam': 'Chamorro Standard Time',
      'Pacific/Chuuk': 'Chuuk Time',
      'Pacific/Port_Moresby': 'Papua New Guinea Time',
      'Asia/Ust-Nera': 'Vladivostok Standard Time - Ust-Nera',
      'Asia/Vladivostok': 'Vladivostok Standard Time - Vladivostok',
      'Australia/Adelaide': 'Central Australia Time - Adelaide',
      'Australia/Broken_Hill': 'Central Australia Time - Broken Hill',
      'Pacific/Bougainville': 'Bougainville Time',
      'Antarctica/Casey': 'Casey Time',
      'Australia/Hobart': 'Eastern Australia Time - Hobart',
      'Antarctica/Macquarie': 'Eastern Australia Time - Macquarie',
      'Australia/Melbourne': 'Eastern Australia Time - Melbourne',
      'Australia/Sydney': 'Eastern Australia Time - Sydney',
      'Pacific/Kosrae': 'Kosrae Time',
      'Australia/Lord_Howe': 'Lord Howe Time',
      'Asia/Magadan': 'Magadan Standard Time',
      'Pacific/Noumea': 'New Caledonia Standard Time',
      'Pacific/Pohnpei': 'Ponape Time',
      'Asia/Sakhalin': 'Sakhalin Standard Time',
      'Pacific/Guadalcanal': 'Solomon Islands Time',
      'Asia/Srednekolymsk': 'Srednekolymsk Time',
      'Pacific/Efate': 'Vanuatu Standard Time',
      'Asia/Anadyr': 'Anadyr Standard Time',
      'Pacific/Fiji': 'Fiji Standard Time',
      'Pacific/Tarawa': 'Gilbert Islands Time',
      'Pacific/Kwajalein': 'Marshall Islands Time - Kwajalein',
      'Pacific/Majuro': 'Marshall Islands Time - Majuro',
      'Pacific/Nauru': 'Nauru Time',
      'Pacific/Norfolk': 'Norfolk Island Time',
      'Asia/Kamchatka': 'Petropavlovsk-Kamchatski Standard Time',
      'Pacific/Funafuti': 'Tuvalu Time',
      'Pacific/Wake': 'Wake Island Time',
      'Pacific/Wallis': 'Wallis & Futuna Time',
      'Pacific/Apia': 'Apia Standard Time',
      'Pacific/Auckland': 'New Zealand Time',
      'Pacific/Kanton': 'Phoenix Islands Time',
      'Pacific/Fakaofo': 'Tokelau Time',
      'Pacific/Tongatapu': 'Tonga Standard Time',
      'Pacific/Chatham': 'Chatham Time',
      'Pacific/Kiritimati': 'Line Islands Time',
    },
    $Validators: {
      password: 'The {field} must be contain minimum six characters, one uppercase, one lowercase, one number and one special character.',
      businessName: 'The {field} must be contain only letters, numbers, underscore, hyphen or spaces.',
    },
    $NavMenu: {
      ContactList: 'Contact list',
    },
    $PageTitle: {
      CampaignsAnalytics: 'Campaign analytics',
      ContactsAnalytics: 'Contact analytics',
      ReviewsAnalytics: 'Review analytics',
      FeedbackAnalytics: 'Feedback analytics',
    },
    $Auth: {
      Login: 'Log in',
      OrLogin: 'OR',
      LoginAttempt: 'Log in attempt',
      SignUpAttempt: 'Sign up attempt',
      LoginWelcomeTitle: 'Welcome back. Please log in to your account.',
      SuccessfullyLoginMsg: 'You are already logged in',
      SuccessfullySignUpMsg: 'You already have an account',
      SignUp: 'Sign up',
      SignUpWithEmail: 'Sign up with email',
      SignUpWithGoogle: 'Sign up with Google',
      SignUpFree: 'Sign up free',
      ReviewTermsConditionsToRegister: 'Review the Terms and Conditions to register',
      RememberMeMsg: 'Remember me',
      HowDidYouHearAboutUsMsg: 'How did you hear about us?',
      ReferralCode: 'Referral code?',
      AcceptTermsAndConditions: 'I accept the Terms & Conditions.',
      BackToLogin: 'Back to login',
      YouAreNotAuthorizedTitle: 'You are not authorized',
      YouAreNotAuthorizedMsg: 'You do not have the permissions to access this content. If this is an error, please contact support.',
      BackToDashboard: 'Back to dashboard',
      ResetPasswordTitle: 'Reset password',
      StartWithMyAccount: 'Start with my account',
      ConfirmTermsConditionsTitle: 'Confirm Terms & Conditions',
      ConfirmTermsConditionsMsg: 'Please confirm that you accept the Terms & Conditions to start using your account.',
      NewPassword: 'New password',
      ResetPasswordMsg: 'Please enter your new password.',
      ResetPasswordSuccess: 'Password reset successfully',
      InviteSignUpTitle: 'Complete your sign up',
      InviteSignUpMsg: 'Please enter your user information to complete the sign up.',
      EmailVerificationErrorNotifyMsg: 'An error has occurred with the verification of your email account',
      $EmailAccountVerification: {
        VerificationModalTitle: 'Email account verification: {email}',
        VerificationNotificationTitle: 'Email account verification',
        VerificationNotificationOkMsg: 'Email account verified successfully',
        VerificationNotificationErrorMsg: 'Email account verified successfully',
        InvalidLinkClickedErrorMsg: 'Your verification link is invalid. Request a new one',
      },
    },
    $Errors: {
      $Titles: {
        SessionExpired: 'Session expired',
        Unauthorized: 'Unauthorized',
        BadRequest: 'Bad request',
        PaymentRequired: 'Payment required',
        Forbidden: 'Forbidden',
        NotFound: 'Not found',
        MethodNotAllowed: 'Method not allowed',
        RequestTimeout: 'Request time-out',
        InternalServerError: 'Internal Server Error',
      },
      $Messages: {
        SessionExpired: 'Your session has expired. Log in again',
        GoogleAuthError: 'Error with Google authentication.',
        InvalidCredentials: 'Email or user invalid.',
        ErrorSavingUser: 'An error occurred when saving your information.',
        InvalidSecurityToken: 'Invalid security code. You can request a new one.',
        UnexpectedError: 'An unexpected error has occurred in the request.',
        EmailNotExist: 'The email provided does not exist.',
        MissingScopes: 'You do not have permissions to access.',
        InsufficientScopes: 'You do not have sufficient permissions to access.',
        FailSendEmails: 'An unexpected error occurred when sending emails.',
        EmailRepeat: 'Some email addresses already exist.',
        passwordisrequired: 'The password is required.',
      },
    },
    $Modals: {
      ViewModalTitle: 'View {entity} - {name}',
      CreateModalTitle: 'Create {entity}',
      EditModalTitle: 'Edit {entity} - {name}',
      CloneModalTitle: 'Clone {entity} - {name}',
      ExportModalTitle: 'Export {entity}',
    },
    $Dialogs: {
      ConfirmDeleteTitle: 'Confirm delete',
      ConfirmCancelTitle: 'Confirm cancel',
      ConfirmRestoreTitle: 'Confirm restore',
      ConfirmUpdateTitle: 'Confirm update',
      ConfirmCloseWithoutSaveChangesTitle: 'Confirm close without save changes',
      ConfirmGeneralDeleteMsg: 'Nothing to delete | You are about to delete one "{entity}" | You are about to delete {count} "{entity}"',
      ConfirmSpecificDeleteMsg: 'You are about to delete to "{entity}"',
      ConfirmSpecificOneDeleteMsg: 'You are about to delete one {entity}',
      ConfirmGeneralCancelMsg: 'Nothing to cancel | You are about to cancel one "{entity}" | You are about to cancel {count} "{entity}"',
      ConfirmSpecificCancelMsg: 'You are about to cancel to "{entity}"',
      ConfirmGeneralRestoreMsg: 'Nothing to restore | You are about to restore one "{entity}" | You are about to restore {count} "{entity}"',
      ConfirmSpecificRestoreMsg: 'You are about to restore "{entity}"',
      ConfirmCloseWithoutSaveChangesMsg: 'You are about to close and lose the changes you have made',

      $MissingPermissions: {
        $CompleteAccountDetails: {
          Title: 'You do not have the permissions to complete the account details',
          Message: 'Contact the owner or administrator of your organisation to complete the account details to send campaigns and request reviews.',
        },
      },
    },
    $Notifications: {
      CreationTitle: '{entity} creation',
      CreationMsg: '{entity} created successfully',
      EditionTitle: '{entity} edition',
      UpdateTitle: '{entity} update',
      EditionMsg: '{entity} edited successfully',
      UpdateMsg: '{entity} updated successfully',
      DeletedTitle: '{entity} deleted',
      DeletedMsg: 'Nothing deleted | The selected {entity} was successfully deleted | The selected {entity} were successfully deleted',
      CanceledTitle: '{entity} canceled',
      CanceledMsg: 'Nothing canceled | The selected {entity} was successfully canceled | The selected {entity} were successfully canceled',
      RestoredTitle: '{entity} restored',
      RestoredMsg: 'Nothing was restored | The selected {entity} was successfully restored | The selected {entity} were successfully restored',
      NoSelectionTitle: 'No {entity} selected',
      NoSelectionMsg: 'You must select at least one {entity}',
    },
    $Entities: {
      User: 'No user | User | Users',
      Merchant: 'No Business | Business | Businesses',
      Audience: 'No audience | Audience | Audiences',
      SMSTemplate: 'No SMS template | SMS template | SMS templates',
      MMSTemplate: 'No MMS template | MMS template | MMS templates',
      Recording: 'No recording | Recording | Recordings',
      EmailTemplate: 'No email template | Email template | Email templates',
      ContactGroup: 'No contact groups | Contact group | Contact groups',
      Contact: 'No contacts | Contact | Contacts',
      ContactAttribute: 'No custom attributes | Custom attribute | Custom attributes',
      Tag: 'No tag | Tag | Tags',
      Segment: 'No segments | Segment | Segments',
      Plan: 'No plan | Plan | Plans',
      SMSCampaign: 'No SMS campaign | SMS campaign | SMS campaigns',
      SMSInbound: 'No SMS inbound| SMS inbound | SMS inbounds',
      MMSCampaign: 'No MMS campaign | MMS campaign | MMS campaigns',
      VoiceCampaign: 'No voice campaign | Voice campaign | Voice campaigns',
      EmailCampaign: 'No Email campaign | Email campaign | Email campaigns',
      CallerID: 'No caller number | Caller number | Caller numbers',
      MailerID: 'No sender email | Sender email | Sender emails',
      AppSettings: 'No app settings | App setting | App settings',
      AppService: 'No app service | App service | App services',
    },
    $AppFilters: {
      $Operators: {
        [enums.AppFilterOperator.AND]: 'AND',
        [enums.AppFilterOperator.OR]: 'OR',
      },
      $Operation: {
        [enums.AppFilterOperation.CONTAINS]: 'Contains',
        [enums.AppFilterOperation.NOT_CONTAINS]: 'Not contains',
        [enums.AppFilterOperation.EQUALS]: 'Equals',
        [enums.AppFilterOperation.NOT_EQUAL]: 'Not equal',
        [enums.AppFilterOperation.STARTS_WITH]: 'Starts with',
        [enums.AppFilterOperation.ENDS_WITH]: 'Ends with',
        [enums.AppFilterOperation.LESS_THAN]: 'Less than',
        [enums.AppFilterOperation.LESS_THAN_OR_EQUAL]: 'Less than or equal',
        [enums.AppFilterOperation.GREATER_THAN]: 'Greater than',
        [enums.AppFilterOperation.GREATER_THAN_OR_EQUAL]: 'Greater than or equal',
        [enums.AppFilterOperation.IN_RANGE]: 'In range',
        [enums.AppFilterOperation.ONE]: 'Includes any',
        [enums.AppFilterOperation.ALL]: 'Includes all',
        [enums.AppFilterOperation.NONE]: 'Excludes all',
        [enums.AppFilterOperation.NONE_ANY]: 'Exclude any',
      },
      $FilterMatch: {
        [enums.AppFilterMathType.ALL]: 'All',
        [enums.AppFilterMathType.ANY]: 'Any',
      },
      $FilterType: {
        $Boolean: {
          $Values: {
            [enums.AppFilter.FilterType.Boolean.Values.UNDETERMINED]: 'Undetermined',
            [enums.AppFilter.FilterType.Boolean.Values.YES]: 'Yes',
            [enums.AppFilter.FilterType.Boolean.Values.NO]: 'No',
          },
        },
      },
    },
    $Tours: {
      $WelcomeTour: {
        StepToggleSidebar: 'Toggle sidebar collapse',
        StepBookmarks: 'Create your own bookmarks and rearrange them using drag and drop.',
        StepSearch: 'Enter keywords to search pages.',
        StepFooter: 'Leave us your feedback or contact us if you have any problems.',
      },
    },
    $Components: {
      $CardCountClassifiedListSMSCampaigns: {
        CreateNewCampaign: 'Create a new campaign',
      },
      $TheFooter: {
        ChatLinkText: 'Chat with us',
      },
      $BaseAgGridHeader: {
        FiltersChipMsg: 'without filters | 1 filter | {count} filters must match',
      },
      $PostLoginBusinessDetails: {
        Title: 'Please complete your business information',
        SaveButtonText: 'Save your business details',
      },
      $ExportJsonToExcel: {
        ColumnsToExport: 'Columns to export',
        EnterFileName: 'Enter file name',
        SendMeByEmail: 'Send it to me by email',
      },
      $ShareResource: {
        SharedWith: 'Shared with',
        SelectUsersToSharePlaceholder: 'Select the users you want to share with',
        NoCollaboratorsMsg: 'You have no collaborators.',
        SelectPublicMsg: 'Everyone in the organisation can see it.',
        CanView: 'Can view',
        CanEdit: 'Can edit',
        CanEditAndShare: 'Can edit and share',
        HideShareOptions: 'Hide and share options',
        ShowShareOptions: 'Show share options',
      },
      $CategoriesDropDownFilter: {
        OneOf: 'one of',
        AllOf: 'all of',
        NoneOf: 'none of',
      },
      $AddCreditCard: {
        CardholderName: 'Cardholder name',
      },
      $PackageCheckoutModal: {
        SelectPrepaidPackageLabel: 'Select one package',
        PrepaidPackageOptionText: 'Prepaid package of {price}',
        MonthlyPackageOptionText: 'Monthly package of {price}',
        CompleteYourAccountDetailsToPay: 'Complete your account details to make the payment',
      },
      $UserPackageCardInfo: {
        ValidUntil: '<strong>Valid until:</strong> {date}',
        YouCanSend: 'You can send {quantity} {service}',
      },
      $UploaderPreviewImage: {
        MaxImageSizeMsg: 'The maximum supported file size is 5 MB',
        ValidImageFormats: 'Valid image formats: {formats}',
      },
      $ReviewsFeedbackList: {
        ReviewDetails: 'Review details',
        GoogleReviewSummary: 'Review details',
      },
      $GoogleReviewsSummaryCard: {
        SetupGoogleReviews: 'Set up Google Reviews',
      },
    },
    $DashboardAnalyticsPage: {
      CampaignsAnalyticsCardTitle: 'SMS campaign analytics',
      ContactsAnalyticsCardTitle: 'Contact analytics',
      CampaignsLineChartsNoDataTitle: 'No campaign data to display',
      CampaignsLineChartsNoDataSubtitle: 'Select another date range or create a new campaign.',
      ContactsLineChartsNoDataTitle: 'No contact data to display',
      ContactsLineChartsNoDataSubtitle: 'Select another date range or create a new contact.',
      ContactsPieChartsNoDataTitle: 'No contact data to display',
      ContactsPieChartsNoDataSubtitle: 'Add new contacts to start viewing.',
      DateRangeFilterPlaceholder: 'Select a range',
      ContactsBehaviorTabsTitle: 'Contacts behavior',
      ContactsMarketingStatusTabsTitle: 'Contacts status',
    },
    $DashboardFeedbackPage: {
      TotalFeedbackReceivedCardTitle: 'Total feedback received',
      AverageFeedbackRatingCardTitle: 'Average feedback rating',
      TotalGoodFeedbackCardTitle: 'Good Feedback',
      TotalMediumGoodFeedbackCardTitle: 'Neutral Feedback',
      TotalBadFeedbackCardTitle: 'Bad Feedback',
    },
    $UserModule: {
      InviteUsers: 'Invite users',
      EmailAddresses: 'Email addresses',
      $Permissions: {
        ownerPermissionName: 'Owner',
        ownerPermissionDescription: 'There must be at least one Owner per organisation. Only the Owner can assign or remove other Owners.',
        adminPermissionName: 'Administrator',
        adminPermissionDescription: 'An Administrator has unrestricted access to all assets.',
        usersPermissionName: 'User Editor',
        usersPermissionDescription: 'A User Editor can assign permissions, invite new users, and remove users.',
        merchantsPermissionName: 'Merchant Editor',
        merchantsPermissionDescription: 'Description',
        businessPermissionName: 'Business Editor',
        businessPermissionDescription: 'A Business Editor can buy services, pay bills, update payment options, upgrade or downgrade subscription plans, and export invoices.',
        campaignsPermissionName: 'Campaign Editor',
        campaignsPermissionDescription: 'A Campaign Editor can manage contacts, review links, and message templates to send, schedule, and cancel campaigns.',
      },
    },
    $RecordingModule: {
      RecordingFrom: 'Recording From:',
      UploadFile: 'Upload File',
      RecordMessage: 'Record Message',
      AudioFileRequiredMsg: 'An audio file is required',
      ValidFileFormatsMsg: 'Valid file formats: {formats}',
      StopAudio: 'Stop Audio',
      PlayAudio: 'Play Audio',
    },
    $MMSTemplateModule: {
      ImageFileOptionalMsg: 'The image file is optional',
      ValidFileFormatsMsg: 'Valid file formats: {formats}',
    },
    $EmailTemplateModule: {
      CreateTheFirst: 'Create the first',
      DoNotHaveEmailTemplate: 'You don\'t have an email template yet.',
    },
    $ContactGroupModule: {
      CrateContactModalTitle: 'Create contact in {groupName}',
      ManageContactsGroups: 'Manage contacts groups',
      SelectContactGroups: 'Select contact groups',
    },
    $ContactModule: {
      ContactAttributes: 'Contact attributes',
      AddAttribute: 'Add a contact attribute',
      CreateAttribute: 'Create a contact attribute',
      ManageAttributes: 'Manage contact attributes',
      ManageSegments: 'Manage segments',
      NewSegment: 'New segment',
      ViewSegment: 'View segment',
      EditSegment: 'Edit segment',
      InvalidPhoneNumberMsg: 'Invalid phone number.',
      PhoneNumberRequiredMsg: 'A phone number is required.',
      SelectCategoriesOptions: 'Select category options',
      ChooseCategories: 'Choose categories',
      ManageAudienceAttributes: 'Manage custom attributes',
      ChooseATag: 'Choose a tag',
      CleanTags: 'Clean tags',
      ManageAudienceTags: 'Manage tags',
      SelectTags: 'Select tags',
      AssignedUsersLabel: 'Assigned users',
      ShowMoreItemCellRenderers: '+ {count} more',
      CheckDNCAction: 'Check DNC',
      CheckDNCNotifyTitle: 'Do Not Call Register (DNC) verification',
      CheckDNCVerificationNotifyTitle: 'Verifying contacts. We will notify you when ready',
      CheckDNCVerificationSuccessNotifyTitle: 'You\'ve checked if {contacts} contacts are registered in the Australian Do Not Call Register for {cost}',
      CheckDNCNotifyNoSelectedAuContactsMsg: 'You must select at least one Australian contact number',
      ConfirmCheckDNCTitle: 'Confirm Do Not Call Register (DNC) verification',
      ConfirmCheckDNCMsg: 'You are about to check if {contacts} non-duplicate Australian contacts are registered in the Australian Do Not Call Register for {cost}.',
      $ErrorCheckDNC: {
        InsufficientBalance: 'Your balance is insufficient to verify these contacts. Top up your account balance and try again.',
        BalanceFrozen: 'You currently do not have enough available balance to verify these contacts. Top up your account balance and try again.',
      },
      $ImportContacts: {
        ContactsManagement: 'Contacts management',
        BlacklistImportedContacts: 'Import contacts as unsubscribed',
        BlacklistImportedContactsMsg: 'These contacts wish not to be contacted, and you want to protect their right to opt out of your list.',
        OptInAgreement: 'Opt-in Agreement',
        OptInAgreementConditionsMsg: `| I confirm that my new contact adheres to these conditions:
        | I confirm that my new import adheres to these conditions:`,
        OptInAgreementConditionsPopoverMsg: `| I confirm that my new contact adheres to
        | I confirm that my new import adheres to`,
        OptInAgreementConditionsPopoverBtn: 'these conditions',
        OptInAgreementConditions1: `| My contact explicitly gave me their permission to send SMS within the last two years, or had been asked to within the last two years.
        | My contacts explicitly gave me their permission to send SMS within the last two years, or had been asked to within the last two years.`,
        OptInAgreementConditions2: `| This contact was not borrowed from a third party.
        | These contacts were not borrowed from a third party.`,
        OptInAgreementConditions3: `| This contact was not purchased or rented.
        | These contacts were not purchased or rented.`,
        OptInAgreementConditionsSuspendMsg: 'We may suspend or cancel any SMS sent to contacts that don\'t adhere to these conditions at any time.',
        OptInAgreementLearnMore: 'Learn more about opt-in contacts',
      },
      $CreateOrEdit: {
        SelectCategoryPlaceholder: 'Select the items category for this contact',
      },
    },
    $AttributeModule: {
      AttributeType: 'Attribute type',
      AttributeName: 'Attribute name',
      CategoryName: 'No category names | Category name | Category names',
    },
    $CampaignsModules: {
      ConfirmCampaign: 'Confirm campaign',
      CampaignInfo: 'Campaign info',
      CampaignDeliveryType: 'Delivery type',
      ConfirmTestCampaignTitle: 'Confirm test campaign',
      ConfirmTestCampaignMsg: 'You are about to send one {type} to {senderId} for {cost}',
      ConfirmStartOverCampaignTitle: 'Confirm start over campaign',
      ConfirmStartOverCampaignMsg: 'You are about to start over the campaign. You will lose all your changes.',
      ConfirmDiscardDraftCampaignTitle: 'Confirm discard draft campaign',
      ConfirmDiscardDraftCampaignMsg: 'You are about to discard the campaign. You will lose all the saved changes',
      ConfirmDiscardDraftCampaignNtfTitle: 'Discard draft campaign',
      ConfirmDiscardDraftCampaignNtfMsg: 'The draft campaign was discarded successfully',
      ConfirmStartOverCampaignNtfTitle: 'Start over campaign',
      ConfirmStartOverCampaignNtfMsg: 'The campaign was start over successfully',
      ConfirmCancelScheduledCampaignTitle: 'Confirm cancel scheduled campaign',
      ConfirmCancelScheduledCampaignMsg: 'You are about to cancel the scheduled campaign. You will lose all the saved changes',
      SelectContactsErrorTitle: 'Contacts selection',
      SelectContactsErrorMsg: 'You must select at least one contact or group',
      ConfirmSendCampaignTitle: 'Confirm the sending of the campaign',
      ConfirmSendCampaignMsg: 'You are about to send one {entity} to {contacts} contacts for {cost}',
      ConfirmScheduleCampaignTitle: 'Confirm the scheduling of the campaign',
      ConfirmScheduleCampaignMsg: 'You are about to schedule one {entity} to {contacts} contacts for {cost}',
      ConfirmSaveAndQuitCampaignTitle: 'Confirm save & quit campaign',
      ConfirmSaveAndQuitCampaignMsg: 'Your are about to save and quit this campaign',
      ConfirmSaveAndQuitCampaignNtfTitle: 'Save & quit campaign',
      ConfirmSaveAndQuitCampaignNtfMsg: 'The campaign was saved as a draft successfully',
      TestCampaignSuccessNotifyTitle: 'Test campaign',
      TestCampaignSuccessNotifyMsg: 'Test SMS sent successfully',
      DetailGeneralChartTitle: 'Statistics of {entity} ',
      DetailCampaignTitle: 'Campaign details',
      DetailCampaignCompareTitle: 'Campaigns compare',
      CampaignStartImmediately: 'Start immediately',
      CampaignScheduleLater: 'Schedule',
      LocalStartTime: 'Local start time',
      CampaignContacts: 'Campaign contacts',
      CampaignMessage: 'Campaign message',
      CampaignCost: 'Campaign cost',
      DiscardDraft: 'Discard draft',
      InSufficientBalanceToSendCampaignNtf: {
        Title: 'You don\'t have enough balance',
        Text: 'You must top up your balance or modify your campaign to be able to send it',
        AcceptText: 'Top up balance',
        CancelText: 'Modify campaign',
      },
      $CampaignConfirm: {
        CampaignReadyToSendTitle: 'Your campaign is ready to be sent for: <span class="font-bold">{cost}</span>',
        CampaignReadyToSendSubtitle: 'Review the report below before sending your campaign.',
        CampaignReadyToScheduleTitle: 'Your campaign is ready to be scheduled for: <span class="font-bold">{cost}</span>',
        CampaignReadyToScheduleSubtitle: 'Review the report below before scheduling your campaign. ',
        ContactsStepSubtitle: 'Total number of contacts: {count}',
        ContactsYouCanSend: 'Contacts you can send to: {count} / {total}',
        ContactsWithCutMessages: 'Contacts with cut messages : {count}',
        ContactsWithCutMessagesMsg: 'Because the message length for some contacts exceeds the maximum length to be sent as a single message, the message for these contacts will be shortened to be sent.',
        InsufficientBalanceMsg1: 'You do not have enough balance to send all messages. Add more balance',
        InsufficientBalanceMsg2: 'to complete your campaign and send to all contacts',
        ReturnToStepLink: 'Return to this step',
      },
      $ErrorSendTest: {
        InsufficientBalance: 'Your balance is insufficient to test this campaign. You can increase your balance here',
        BalanceFrozen: 'You do not have enough balance to test this campaign. There are campaigns running and waiting to discount the corresponding balance. Please wait for campaigns to finish and try again, or increase your balance here',
      },
      $ErrorSend: {
        InsufficientBalance: 'Your balance is insufficient to send this campaign, you can increase your balance here',
        BalanceFrozen: 'You currently do not have enough balance to send this campaign. There are campaigns running and waiting to discount the corresponding balance. Please wait for campaigns to finish and try again, or you can increase your balance here',
      },
      $CampaignDetailsStatus: {
        pending: 'Pending',
        bounced: 'Bounced',
        outbound: 'Outbound',
      },
      $CampaignType: {
        transactional: 'Transactional & reviews',
        Transactional: 'Transactional',
        TransactionalAndReviews: 'Transactional & reviews',
        Marketing: 'Marketing',
        marketing: 'Marketing',
        Review: 'Review',
        review: 'Review',
      },
      $CampaignTypeInfo: {
        Transactional: 'A transactional messages is usually related to account activity or a commercial transaction. Examples include review requests, password reminders, purchase confirmations, and personalised product notifications.',
        Review: 'A review is a transactional message in which you invite your customers to review your business. Manage feedback and increase your online reputation with GrapeSend.',
        Marketing: 'Marketing messages are used to promote your business’s products and services to customers that have opted-in your marketing list. Use marketing messages to generate brand awareness, build relationships, and keep customers engaged between purchases.',
      },
    },
    $QuickCampaignsModules: {
      ConfirmSendCampaignTitle: 'Confirm quick campaign',
      ConfirmSendCampaignMsg: 'You are about to send one quick {entity} to {contacts} contacts for {cost}',
    },
    $TextCampaignsModules: {
      TextMessageExceededLimitTitle: 'Your message contains placeholders',
      TextMessageExceededLimitMsg: 'The length of your messages will be better calculated in the last step, where you can find a more accurate estimate.',
      ReviewCampaignPlaceholder: 'Hi {{ firstName }}, it was great seeing you today. Would you take one minute to leave a review about your experience? Here is the link {{ reviewLink }}. Thanks for your help.',
    },
    $SMSCampaignModule: {
      ResendAnSMSCampaign: 'Resend an SMS campaign',
      CreateAnSMSCampaign: 'Create an SMS campaign',
      EditSMSCampaign: 'Edit SMS Campaign',
      QuickSMSCampaignPageTitle: 'Create a quick SMS',
      ResendQuickSMSCampaignPageTitle: 'Resend a quick SMS',
      EnterMessage: 'Enter message',
      InsertSMSTemplate: 'Insert an SMS template',
      InsertSMSTemplateButton: 'Insert',
      SelectSMSTemplatePlaceholder: 'Select an SMS template',
      ManageSMSTemplates: 'Manage SMS templates',
      InsertShortUrlLabel: 'Insert short URL',
      InsertShortUrlButton: 'Insert URL',
      InsertShortUrlPlaceholder: 'Enter a URL to short and insert',
      SMSInbound: 'No SMS Inbound | SMS inbound | SMS inbounds',
      SMSDraft: 'No SMS Drafts | SMS drafts | SMS drafts',
      SMSHistory: 'No SMS history | SMS history | SMS history',
      SMSScheduled: 'No Scheduled SMS | Scheduled SMS | Scheduled SMS',
      ReviewLinkMissingNotifyTitle: 'A reviews campaign requires a review link',
      ReviewLinkMissingNotifyMsg: 'You must insert a review link to continue the campaign or change the type of campaign if you do not want a reviews campaign.',
      ReviewLinkMissingAcceptButtonText: 'Insert link',
      ReviewLinkMissingCancelButtonText: 'Change type',
      WeekDayRestrictionTimeAlert: {
        Title: 'Marketing campaigns not must send in this schedule',
        Text: 'Marketing campaigns can only be sent on weekdays between 9 am and 8 pm and Saturdays between 9 am and 5 pm to minimize inconvenience to the consumer.',
      },
    },
    $MMSCampaignModule: {
      EnterMessage: 'Enter message',
      AddMMSTemplate: 'Add an MMS Template',
    },
    $EmailCampaignModule: {
      AddEmailTemplate: 'Add an email template',
      SelectEmailTemplateMsg: 'Use this template',
      EmailDraft: 'No email drafts | Email drafts | Email drafts',
      EmailHistory: 'No email History | Email history | Email history',
      EmailScheduled: 'No scheduled email | Scheduled email | Scheduled email',
    },
    $VoiceCampaignModule: {
      SelectRecordingMsg: 'Select a campaign recording to send',
      CampaignRecordingNotifyErrorTitle: 'Campaign recording',
      CampaignRecordingNotifyErrorMsg: 'You must select one recording to send.',
      VoiceDraft: 'No voice drafts | Voice drafts | Voice drafts',
      VoiceHistory: 'No voice history | Voice history | Voice history',
      VoiceScheduled: 'No voice scheduled | Voice scheduled | Voice scheduled',
    },
    $SenderIdsModules: {
      VerificationModalTitle: '{entity} verification: {name} ({value})',
    },
    $CallerIdModule: {
      InvalidPhoneNumberMsg: 'Invalid phone number.',
      PhoneNumberRequiredMsg: 'Phone number is required.',
      VerifyPhoneMsg: 'You will receive an SMS with a verification code. If you already have a code, please enter it below',
      ManageCallerIds: 'Manage caller numbers',
    },
    $MailerIdModule: {
      VerifyEmailMsg: 'You will receive an email with a verification code and a link. If you already have a code, please enter it below',
      ManageMailerIds: 'Manage sender emails',
    },
    // todo: remove this $SharedByManyModules when unused
    $SharedByManyModules: {
      SelectedAllPageInListMsg: 'All {count} {entity} on this page have been selected.',
      SelectAllInListMsg: 'Select all {count} {entity}.',
    },
    $AppServiceModule: {
      PricesBasedOnCountry: 'Price based on country',
      CostsBasedOnUsd: 'Cost based on USD',
      PackageBased: 'Package based',
      CurrentVersion: 'Current version',
      NewVersion: 'New version',
      ServiceNotSupportPriceCountryMsg: 'This service does not support auto generation for countries.',
      PackagesInformation: 'Packages information',
      ItemsInformation: 'Items information',
      MonthlyDiscount: 'Monthly discount',
      UsdExchangeRate: 'USD exchange rate',
      AvailableCountries: 'Available countries',
      SelectCountry: 'Select a country',
      costXUnitUsd: 'Cost x unit(USD)',
      costXUnitAud: 'Cost x unit(AUD)',
      Margin: 'Margin(%)',
      PrepaidUnitPrice: 'P.P x unit(AUD)',
      MonthlyUnitPrice: 'M.P x unit(AUD)',
      Price: 'Price(AUD)',
      FirstMonthFee: 'First month fee(AUD)',
      FirstMonthFeeName: 'First month fee name',
      FirstMonthPrice: 'First month price(AUD)',
      AddItem: 'Add item',
    },
    $ReviewsSettingsModule: {
      $ReviewsSettingsView: {
        EditSettingButtonText: 'Edit settings',
        SetupMissingMsg: 'Online reviews are the front of your business reputation and digital presence.',
        SetupNowButtonText: 'Set up now',
        RequestReviewButtonText: 'Request reviews',
        SetupGoogleReviews: 'Boost your business with Google Reviews',
      },
      $ReviewsSettingsCreateOrEdit: {
        SaveReviewsSettingsNotifyTitle: 'Update review settings',
        SaveReviewsSettingsNotifyOkMsg: 'Your reviews settings have been saved successfully',
        SaveReviewsSettingsNotifyErrorMsg: 'You must choose a business',
      },
      $BusinessTab: {
        TabTitle: 'Select your business',
        AutocompleteLabel: 'What\'s the name of your business?',
        AutocompletePlaceholder: 'Business name',
        MapLabel: 'You can also search and select your business directly on the map.',
        IsYourBusinessMissing: 'Is your business missing?',
        CreateGoogleListing: 'Create a Google listing',
        CreateGoogleListingMsg: 'It may take up to 3 business days for Google to review and approve your business.',
      },
      $ConfirmBusinessTab: {
        TabTitle: 'Confirm your business',
        Title: 'We found this business listing on Google',
        Subtitle: 'Is this your business?',
        ConfirmButtonText: 'Yes, use this listing',
        CancelButtonText: 'No, let my try again',
      },
      $SetupCompletedTab: {
        TabTitle: 'Start to send reviews',
        Title: 'You\'re off to a great start',
        Subtitle: 'Keep building your score by getting more reviews.',
        RequestReviewButtonText: 'Request reviews',
        CopyReviewLinkLinkText: 'Copy review link',
        CopyReviewLinkCopiedNotifyTitle: 'Copy review link',
        CopyReviewLinkCopiedNotifyOkMsg: 'Review link copied to clipboard',
        CopyReviewLinkCopiedNotifyErrorMsg: 'The review link could not be copied to clipboard',
      },
    },
    $UserSettings: {
      $Account: {
        $Details: {
          TabTitle: 'Account details',
        },
      },
      $Billing: {
        $PaymentMethods: {
          TabTitle: 'Payment methods',
        },
        $History: {
          TabTitle: 'Billing history',
        },
      },
      $Shop: {
        $MyPurchases: {
          TabTitle: 'My purchases',
          $PrepaidPackages: {
            TabTitle: 'My prepaid packages',
          },
          $DedicatedNumbers: {
            TabTitle: 'My dedicated numbers',
          },
        },
        $ManageTopUps: {
          TabTitle: 'Manage top-ups',
          $TopUpAccount: {
            TabTitle: 'Top up account',
            ServiceSelectPlaceholder: 'Select a service',
            CountrySelectPlaceholder: 'Select a country',
            AboutCreditAndCountriesMsg: 'Purchased credit can be used to send to any available country. All pricing is in Australian Dollars (GST excluded). Credits have an expiry date <a href="{url}" class="link-contextual" target="_blank">(more info here)</a>.',
            UnitPriceMsg: 'Approx {price} each',
            PayModalTitle: 'Pay {item}',
            SubscriptionModalTitle: 'Subscribe to {item} per month',
            PackagePurchaseNotificationTitle: 'Prepaid package payment attempt',
            PackagePurchaseNotificationMsg: 'The payment will be processed in a few seconds',
          },
          $AutoTopUpAccount: {
            TabTitle: 'Auto top-up',
          },
        },
        $DedicatedNumbers: {
          TabTitle: 'Dedicated numbers',
        },
      },
    },
    $Stripe: {
      $PaymentIntent: {
        $Status: {
          requires_payment_method: 'Requires a payment method',
          requires_confirmation: 'Requires confirmation',
          requires_action: 'Requires action',
          processing: 'Processing',
          requires_capture: 'Requires capture',
          canceled: 'Canceled',
          succeeded: 'Succeeded',
        },
      },
      $Invoice: {
        $Status: {
          draft: 'Draft',
          open: 'Open',
          paid: 'Paid',
          uncollectible: 'Uncollectible',
          void: 'Void',
        },
      },
      $Card: {
        $Brand: {
          amex: 'American Express',
          diners: 'Diners Club',
          discover: 'Discover',
          jcb: 'JCB',
          mastercard: 'MasterCard',
          unionpay: 'UnionPay',
          visa: 'Visa',
          unknown: 'Unknown',
        },
      },
    },
    $Pages: {
      $Home: {
        CompleteActivationStepsTitle: 'Activate campaigns for your account',
        CompleteActivationStepsSubtitle: 'Please follow these steps to activate your account for sending campaigns.',
        CompleteActivationStepsChartTitle: 'Activation steps',
        $ActivationSteps: {
          $CompleteAccountDetails: {
            Title: 'Complete your account details',
            Subtitle: 'Fill out your account details to complete your sign-up.',
          },
          $AddOrImportContacts: {
            Title: 'Add or import your contacts',
            Subtitle: 'Upload your contacts to start sending campaigns.',
          },
          $SetUpGoogleReviews: {
            Title: 'Request your first Google review',
            Subtitle: 'Set up a Google reviews link to send your review request.',
          },
          $SendOrScheduleCampaign: {
            Title: 'Send or schedule your first campaign',
            Subtitle: 'Set up, design, and send your campaign.',
          },
        },
      },
      $ContactOptOut: {
        Title: 'You are about to {opt} of {company} marketing notifications',
        Msg: 'If you are sure to {opt}, press the button below to confirm.',
        ConfirmButtonText: 'Confirm {opt}',
        OptOut: 'Opt-out',
        OptIn: 'Opt-in',
        OptOutSuccessfulTitle: '{opt} successful',
        OptOutSuccessfulMsg: 'You will no longer receive marketing notifications from {company}',
        OptInSuccessfulMsg: 'You will receive marketing notifications from {company}',
        OptOutSuccessfulOptInMsg: 'If you want to receive marketing notifications from {company} again, use the link below:',
        OptInSuccessfulOptInMsg: 'If you do not wish to receive {company} marketing notifications, use the link below:',
        LinkCopiedSuccessfully: 'Link copied successfully',
        ErrorCopyingLink: 'Error copying link',
      },
      $Notifications: {
        NoNotificationMsg: 'It seems that you still have no notifications',
      },
    },

    // ##################################### TEMPLATE BASE ########################################
    parent: { inner: 'nested' },
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    ExportSelected: 'Export Selected',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
  },
};
