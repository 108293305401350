import enums from '@/enums';

export default [
  {
    url: '/home',
    name: 'Home',
    icon: 'HomeIcon',
    i18n: '$General.Home',
    slug: 'home',
  },
  {
    url: null,
    name: 'Analytics',
    icon: 'BarChart2Icon',
    i18n: '$General.Analytics',
    slug: 'analytics',
    submenu: [
      {
        url: '/analytics/campaigns',
        name: 'Campaign analytics',
        icon: 'MessageCircleIcon',
        i18n: '$General.Campaign',
        slug: 'campaigns-analytics',
      },
      {
        url: '/analytics/contacts',
        name: 'Contact analytics',
        icon: 'BookIcon',
        i18n: '$Entities.Contact',
        slug: 'contacts-analytics',
      },
      {
        url: '/analytics/reviews',
        name: 'Review analytics',
        icon: 'StarIcon',
        i18n: '$General.Review',
        slug: 'reviews-analytics',
      },
      {
        url: '/analytics/feedback',
        name: 'Feedback analytics',
        icon: 'ActivityIcon',
        i18n: '$General.Feedback',
        slug: 'feedback-analytics',
        roles: [enums.Auth.Role.ADMIN],
      },
    ],
  },
  {
    url: '/app-settings',
    name: 'App Setting',
    icon: 'SettingsIcon',
    i18n: '$Entities.AppSettings',
    slug: 'app-settings',
    roles: [enums.Auth.Role.ADMIN],
  },
  {
    url: '/app-services',
    name: 'Services',
    icon: 'LayersIcon',
    i18n: '$Entities.AppService',
    slug: 'app-services',
    roles: [enums.Auth.Role.ADMIN],
  },
  {
    url: '/merchants',
    name: 'merchants',
    icon: 'UserCheckIcon',
    i18n: '$Entities.Merchant',
    slug: 'merchants',
    roles: [enums.Auth.Role.ADMIN],
  },
  {
    header: 'Services',
    icon: 'MessageCircleIcon',
    i18n: '$General.Service',
    i18nCount: 2,
    slug: 'services',
    items: [
      {
        name: 'SMS Campaign',
        icon: 'MessageSquareIcon',
        i18n: '$General.SMS',
        slug: 'sms-campaigns',
        submenu: [
          {
            url: '/sms-campaigns/create',
            name: 'SMS Campaign Create',
            icon: 'MessageCircleIcon',
            i18n: '$Entities.SMSCampaign',
            i18nCount: 1,
            slug: 'sms-campaigns-create',
          },
          {
            url: '/sms-campaigns/quick',
            name: 'SMS Campaign Quick',
            icon: 'SendIcon',
            i18n: '$General.QuickSMS',
            slug: 'sms-campaigns-quick',
          },
          {
            url: '/sms-campaigns/history',
            name: 'SMS Campaigns History',
            icon: 'ListIcon',
            i18n: '$General.History',
            i18nCount: 1,
            slug: 'sms-campaigns-history',
          },
          {
            url: '/sms-campaigns/scheduled',
            name: 'SMS Campaigns Scheduled',
            icon: 'ClockIcon',
            i18n: '$General.Scheduled',
            i18nCount: 1,
            slug: 'sms-campaigns-scheduled',
          },
          {
            url: '/sms-campaigns/drafts',
            name: 'SMS Campaigns Drafts',
            icon: 'EditIcon',
            i18n: '$General.Draft',
            i18nCount: 2,
            slug: 'sms-campaigns-drafts',
          },
          {
            url: '/sms-inbounds',
            name: 'SMSInbounds',
            icon: 'InboxIcon',
            i18n: '$Entities.SMSInbound',
            slug: 'sms-inbounds',
          },
          {
            url: '/sms-templates',
            name: 'SMSTemplates',
            icon: 'LayoutIcon',
            i18n: '$General.Template',
            i18nCount: 2,
            slug: 'sms-templates',
          },
        ],
      },
      {
        url: '/reviews',
        name: 'Reviews',
        icon: 'StarIcon',
        i18n: '$General.Review',
        slug: 'reviews',
      },
    ],
  },
  {
    header: 'Contacts',
    icon: 'BookIcon',
    i18n: '$Entities.Contact',
    slug: 'contacts',
    items: [
      {
        url: '/contacts',
        name: 'Contact List',
        icon: 'ListIcon',
        i18n: '$NavMenu.ContactList',
        slug: 'contacts',
      },
      {
        url: '/custom-fields',
        name: 'ContactAttributes',
        icon: 'UserCheckIcon',
        i18n: '$Entities.ContactAttribute',
        slug: 'custom-fields',
      },
      {
        url: '/segments',
        name: 'Segments',
        icon: 'FilterIcon',
        i18n: '$Entities.Segment',
        slug: 'segments',
      },
      {
        url: '/tags',
        name: 'Tags',
        icon: 'TagIcon',
        i18n: '$Entities.Tag',
        slug: 'tags',
      },
    ],
  },
  {
    header: 'Senders IDs',
    icon: 'BookIcon',
    i18n: '$General.SenderID',
    items: [
      {
        url: '/caller-ids',
        name: 'Caller IDs',
        slug: 'caller-ids',
        icon: 'PhoneIcon',
        i18n: '$Entities.CallerID',
      },
    ],
  },

  {
    header: 'Knowledge base',
    icon: 'MoreHorizontalIcon',
    i18n: '$General.KnowledgeBase',
    items: [
      {
        url: '/faq',
        slug: 'page-faq',
        name: '$General.FAQ',
        icon: 'HelpCircleIcon',
        i18n: '$General.FAQ',
      },
      {
        url: '/documentation',
        slug: 'page-knowledge-base',
        name: 'Knowledge Base',
        icon: 'BookOpenIcon',
        i18n: '$General.Documentation',
      },
    ],
  },

  {
    header: 'Bin',
    icon: 'MoreHorizontalIcon',
    i18n: '',
    divider: true,
    items: [
      {
        name: 'Bin',
        icon: 'TrashIcon',
        i18n: '$General.Trash',
        slug: 'trash',
        submenu: [
          {
            url: '/contacts/trash',
            name: 'Contacts bin',
            icon: 'BookIcon',
            i18n: '$Entities.Contact',
            slug: 'contacts-trash',
          },
          {
            url: '/sms-templates/trash',
            name: 'SMSTemplates',
            icon: 'MessageSquareIcon',
            i18n: '$Entities.SMSTemplate',
            slug: 'sms-templates-trash',
          },
        ],
      },
    ],
  },
];
