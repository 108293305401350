import AWS from 'aws-sdk';

const bucketName = process.env.VUE_APP_AWS_BUCKET_NAME;
const bucketRegion = process.env.VUE_APP_AWS_BUCKET_REGION;
const IdentityPoolId = process.env.VUE_APP_AWS_IDENTITY_POOL_ID; // must be upper case

AWS.config.region = bucketRegion;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId,
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: bucketName },
});

export const getPreSignedUrlS3 = (key, expires = 0) => {
  const params = { Bucket: bucketName, Key: key };

  if (expires) {
    params.Expires = expires;
  }

  return s3.getSignedUrlPromise('getObject', params);
};

export const uploadFileS3 = (file, path) => s3.upload({
  Bucket: bucketName,
  Key: path,
  Body: file,
  ContentType: file.type,
}).promise();

export const deleteFileS3 = (path) => s3.deleteObject({
  Bucket: bucketName,
  Key: path,
}).promise();
